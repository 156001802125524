export const boostPrice = [
    {
        text: "Full-Day $39 Boost (12-24 hours)",
        price: 39,
        min: 12,
        max: 24
    },
    {
        text: "Extended $29 Boost (5-12 hours)",
        price: 29,
        min: 5,
        max: 12
    },
    {
        text: "Quick $19 Boost (5 hours or less)",
        price: 19,
        min: 1,
        max: 5
    }
]