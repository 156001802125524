import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Card, Image, Typography, Descriptions, Spin, Row, Col, Statistic, Table, InputRef } from 'antd';
import { fetchWithToken } from '../../config/helper';
import { flyer_metrics_url } from '../../config/api';
import type { ColumnsType } from 'antd/es/table';
import {notification} from "../../common/Notification";
import type { Key } from 'antd/es/table/interface';
import getColumnSearchProps from "../../common/ColumnSearch";

const { Title } = Typography;

interface FlyerLog {
    id: string;
    type: string;
    userId: string;
    timestamp: string;
}

const FlyerDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const flyer = location.state?.flyer;
    const [metrics, setMetrics] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const searchInput = useRef<InputRef>(null);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
    });

    const columns: ColumnsType<FlyerLog> = [
        {
            title: 'Action',
            dataIndex: 'type',
            key: 'type',
            filters: [
                { text: 'View', value: 'View' },
                { text: 'Details', value: 'Details' },
                { text: 'Link', value: 'Link' }
            ],
            onFilter: (value: string | number | boolean | Key, flyer: FlyerLog) => 
                flyer.type === String(value),
            render: (type: string) => (
                <span style={{ textTransform: 'capitalize' }}>{type}</span>
            )
        },
        {
            title: 'User',
            dataIndex: 'userId',
            key: 'userId',
            ...getColumnSearchProps(searchInput, 'userId')
        },
        {
            title: 'Date',
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (date: string) => new Date(date).toLocaleString()
        }
    ];
    const fetchMetrics = async (page = 1, pageSize = 10, searchParams: any= {}) => {
        try {
            setLoading(true);
            let params: any = {
                page: String(page),
                pageSize: String(pageSize),
            }
            for (const key of Object.keys(searchParams)) {
                if (searchParams[key]) {
                    params[key] = searchParams[key];
                }
            }
            const queryParams = new URLSearchParams(params);

            const response = await fetchWithToken(`${flyer_metrics_url}/${id}?${queryParams}`, 'get');
            if(response.status !== 200) {
                throw new Error('Error fetching metrics');
            }
            const data = await response.json();

            setMetrics(data)

            setPagination({
                current: page,
                pageSize: pageSize,
                total: data.total
            })  
        } catch (error) {
            console.error('Error fetching flyer metrics:', error);
            notification({
                type: 'error',
                title: 'Error Fetching Metrics',
                description: 'Error fetching metrics'
            });
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchMetrics();
    }, [id]);

    const handleTableChange = (newPagination: any, filters: any) => {
        fetchMetrics(newPagination.current, newPagination.pageSize, filters);
    };

    return (
        <div style={{ 
            padding: '24px',
            maxWidth: '100%',
            overflowX: 'hidden'  // Prevent horizontal scroll
        }}>
        <Row gutter={16} style={{ marginBottom: 24 }}>
            <Col key="views"span={8}>
                <Card>
                    <Statistic
                        title="Views"
                        value={metrics?.counts?.view || 0}
                        loading={loading}
                    />
                </Card>
            </Col>
            <Col key="details" span={8}>
                <Card>
                    <Statistic
                        title="View Details"
                        value={metrics?.counts?.Details || 0}
                        loading={loading}
                    />
                </Card>
            </Col>
            <Col key="link" span={8}>
                <Card>
                    <Statistic
                        title="View Link"
                        value={metrics?.counts?.Link || 0}
                        loading={loading}
                    />
                </Card>
            </Col>
        </Row>
        <Card>
        <div style={{ display: 'flex', gap: '24px' }}>
            <div style={{ flex: '0 0 400px' }}>
            <Image
                src={flyer.promoImage}
                alt={flyer.eventName}
                style={{ width: '100%', objectFit: 'cover' }}
            />
            </div>
            
            <div style={{ flex: 1 }}>
            <Title level={2}>{flyer.eventName}</Title>
            <Descriptions layout="vertical" column={2}>
                <Descriptions.Item label="Description" span={2}>
                {flyer.description}
                </Descriptions.Item>
                <Descriptions.Item label="Venue">
                {flyer.venue}
                </Descriptions.Item>
                <Descriptions.Item label="Event Date">
                {flyer.eventDate ? (
                    `${new Date(flyer.eventDate).toLocaleDateString()}`
                ): (
                    'N/A'
                )}
                </Descriptions.Item>
                <Descriptions.Item label="Location" span={2}>
                {flyer.location}
                </Descriptions.Item>
                <Descriptions.Item label="Promotion Period">
                {flyer.unlimitedPromo ? (
                    `${new Date(flyer.promoBeginDt).toLocaleDateString()} - TBD`
                ) : (
                    `${new Date(flyer.promoBeginDt).toLocaleDateString()} - 
                    ${new Date(flyer.promoEndDt).toLocaleDateString()}`
                )}
                </Descriptions.Item>
                <Descriptions.Item label="Paid">
                {flyer.paid ? 'Yes' : 'No'}
                </Descriptions.Item>
            </Descriptions>
            </div>
        </div>
        </Card>
        
            <Table
                columns={columns}
                dataSource={metrics?.results}
                rowKey="_id"
                loading={loading}
                pagination={{
                    ...pagination,
                    showSizeChanger: true,
                    showTotal: (total) => `Total ${total} items`
                }}
                onChange={handleTableChange}
            />
        </div>
    );
};

export default FlyerDetails;
