import dayjs from "dayjs";
import moment from "moment-timezone";
import {boostPrice} from "../../../../config/constants";

export const ActiveTableColumns = [
    {
        title: "Name",
        key: "name",
        dataIndex: "userId",
        width: "10%",
        render: (data: any) => data?.name
    },
    {
        title: "Email",
        key: "email",
        dataIndex: "userId",
        width: "10%",
        render: (data: any) => data?.email
    },
    {
        title: "Promo Name",
        key: "promoName",
        dataIndex: "promoName",
        width: "10%",
    },
    {
        title: "Event Name",
        key: "eventName",
        dataIndex: "tablerId",
        width: "10%",
        render: (data: any) => {
            return data.eventId[0].eventName
        }
    },
    {
        title: "Location",
        key: "location",
        dataIndex: "placeId",
        width: "10%",
        render: (data: any) => {
            return data.formatted_address
        }
    },
    {
        title: "Event Start Time",
        key: "startTime",
        dataIndex: "tablerId",
        width: "10%",
        render: (data: any) => {
            return moment(data.startTime).tz(data.timeZone).format("DD-MMM-YYYY hh:mm A Z")
        }
    },
    {
        title: "Promo Start Time",
        key: "promoStartTime",
        dataIndex: "startedAt",
        width: "10%",
        render: (text: any) => {
            return dayjs(text).format("DD-MMM-YYYY hh:mm A Z");
        },
    },
    {
        title: "Plan Opted",
        key: "planOpted",
        dataIndex: "planSelected",
        width: "10%",
        render: (data: any) => {
            // Create a formatted string based on the actual data values
            if (data && data.chargedPayment) {
                // If we have all the duration data, show the detailed format
                if (data.minDuration !== undefined && data.maxDuration !== undefined) {
                    return `$${data.chargedPayment} Boost (${data.minDuration} - ${data.maxDuration} hours)`;
                }
                // Otherwise just show the price
                return `$${data.chargedPayment} Boost`;
            }
            
            // Fall back to the lookup method if needed
            const matchedPrice = boostPrice.find((item) => item.price === data?.chargedPayment);
            if (matchedPrice) {
                return matchedPrice.text;
            }
            
            // If no match or no data, return 'Free' or empty string
            return data?.chargedPayment ? `$${data.chargedPayment} Boost` : 'Free';
        }
    },
    {
        title: "Actions",
        key: 'actions',
        dataIndex: '_id',
        width: '10%'
    }
]
