import { useState, useEffect } from 'react';
import { Table, Image, Button, Modal, DatePicker, Input, Form, Switch, Upload, AutoComplete, Checkbox } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { fetchWithToken } from '../../config/helper';
import { cities_url, flyer_url } from '../../config/api';
import SectionHeader from '../../common/Section/SectionHeader';
import SectionBody from '../../common/Section/SectionBody';
import { Moment, now } from 'moment-timezone';
import moment from 'moment';
import dayjs from 'dayjs';
import {notification} from "../../common/Notification";

import type { GetProp, UploadFile, UploadProps } from 'antd';
import { useNavigate } from 'react-router-dom';
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

interface Flyer {
    id: string;
    eventName: string;
    venue: string;
    eventDescription: string;
    eventLink: string;
    promoImage: string;
    eventDate: Moment | null;
    promoBegin: Moment | null;
    promoEnd: Moment | null;
    unlimitedPromo: boolean;
    paid: boolean;
}

const Flyers = () => {
    const [form] = Form.useForm<Flyer>();
    const [flyer, setFlyers] = useState<Flyer[]>([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const [eventName, setEventName] = useState('');
    const [venue, setVenue] = useState('');
    const [eventDescription, setEventDescription] = useState('');
    const [eventLink, setEventLink] = useState('');
    const [promoImage, setPromoImage] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [eventLocation, setEventLocation] = useState('');
    const [promoBegin, setPromoBegin] = useState('');
    const [promoEnd, setPromoEnd] = useState('');
    const [unlimitedPromo, setUnlimitedPromo] = useState(false)
    const [paid, setIsPaid] = useState(false)

    const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
    const [cities, setCities] = useState<{ value: string; label: string, geoNameId: string }[]>([]);

    const fetchFlyers = async () => {
        setLoading(true);
        try {
            const response = await fetchWithToken(flyer_url, 'get');
            const data = await response.json();
            setFlyers(data.results || []);
        } catch (error) {
            console.error('Error fetching campaigns:', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchCities = async () => {
        setLoading(true);
        try {
            const response = await fetchWithToken(cities_url, 'get');
            const data = await response.json();
            setCities(data.data);
        } catch (error) {
            console.error('Error fetching campaigns:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFlyers();
        fetchCities();
        // const interval = setInterval(fetchFlyers, 10000);
        // return () => clearInterval(interval);
    }, []);
    
    const navigate = useNavigate();

    const showModal = () => {
        setIsModalOpen(true);
      };

    const handleCancel = () => {
        form.resetFields();
        fetchFlyers();
        setIsModalOpen(false);
        setPromoImage('');
        setFileList([]);
        setOptions(cities)
    }

    const getBase64 = (img: FileType, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const uploadProps: UploadProps = {
        name:"avatar",
        listType:"picture",
        maxCount: 1,
        showUploadList: false,
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        onChange: async (info) => {
              if(info.fileList.length > 0) {
                const image = document.createElement('img');
                image.src = URL.createObjectURL(info.fileList[0].originFileObj as Blob);

                const file = info.fileList[0].originFileObj as File;

                const isValidType = file.type === 'image/jpeg' || file.type === 'image/png';

                if(!isValidType) {
                    notification({
                        type:  'error',
                        title: 'Invalid Image Selection',
                        description: 'Image is not a .jpg or .png'
                    });
                    return;
                }

                const isValidDimensions = await new Promise<boolean>((resolve) => {
                    image.onload = () => {
                        const isSquare = image.width === image.height;
                        resolve(isSquare);
                    };
                });

                if(!isValidDimensions){
                    notification({
                        type:  'error',
                        title: 'Invalid Image Selection',
                        description: 'Image is not 1:1 aspect ratio'
                    });
                    return;
                }

                if(isValidDimensions && isValidType) {
                    getBase64(info.fileList[0].originFileObj as FileType, (url) => {
                      setLoading(false);
                      setPromoImage(url);
                    });
                } 
              }
          },
          
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };

    const createflyer = () => {
        setLoading(true)
        const flyer = {
            'eventName': eventName,
            'venue': venue,
            'eventDescription': eventDescription,
            'eventLocation': eventLocation,
            'eventLink': eventLink,
            'eventDt': new Date(eventDate),
            'promoImage': promoImage,
            'promoBeginDt': new Date(promoBegin),
            'promoEndDt': unlimitedPromo ? '' : new Date(promoEnd),
            'unlimitedPromo': unlimitedPromo,
            'location': eventLocation,
            'paid': paid
        } 

        fetchWithToken(flyer_url, 'post', {}, JSON.stringify({flyer}))
        .then(async (resp) => {
            const data = await resp.json();
            notification({
                type: resp.status === 200 ? "success" : 'error',
                title: resp.status === 200 ? "Flyer Created Successfully" : 'Create Flyer Failed',
                description: resp.status === 200 ? "Flyer Created" : data.message
            });
            if (resp.status === 200) {
                handleCancel()
            } 
        }).catch(() => {
            notification({
                type:  'error',
                title: 'Create Flyer Failed',
                description: ' Something went wrong'
            });
        }).finally(() => {
            setLoading(false)
            fetchFlyers();
        })
    }

    const columns = [
        {
            title: 'Event Image',
            dataIndex: 'promoImage',
            key: 'promoImage',
            width: 125,
            onCell: () => ({
              style: {
                padding: '0px',    
              }
            }),
            render: (promoImage: string) => (
                <Image
                src={promoImage}
                alt="Preview"
                width={125}  
                height={125}
                style={{ objectFit: 'cover' }}
                />
            )
        },
        {
            title: 'Event Name',
            dataIndex: 'eventName',
            key: 'eventName',
            filters: [
                {text: "Flyer", value: "eventName"},
            ]
        },
        {
            title: 'Venue',
            dataIndex: 'venue',
            key: 'Venue',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Promo Begin',
            dataIndex: 'promoBeginDt',
            key: 'promoBeginDt',
            width: 200,
            render: (date: string) => `${new Date(date).toLocaleString()}`,
        },
        {
            title: 'Promo End',
            dataIndex: 'promoEndDt',
            key: 'promoEndDt',
            width: 200,
            render: (date: string) => `${new Date(date).toLocaleString()}`,
        },
        {
            title: 'Paid',
            dataIndex: 'paid',
            key: 'paid',
            width: 100,
            render: (paid: boolean) => (
                <Checkbox checked={paid} disabled />
            )
        },
        {
            title: 'Created At',
            dataIndex: 'createDt',
            key: 'createDt',
            render: (date: string) => `${new Date(date).toLocaleString()}`,
        },
    ];

    const handleFlyerClick = (flyer: Flyer) => {
      navigate(`/flyers/${flyer.id}`,{
        state: { flyer }  
    });
    };

    return (
        <>
            <SectionHeader heading="Flyers">
                <Button
                      onClick={showModal}
                >
                    Create Flyer
                </Button>
            </SectionHeader>
            <SectionBody>
                <Table
                    // No need since the button is already showing loading
                    // loading={loading}
                    onRow={(flyer) => ({
                        onClick: () => handleFlyerClick(flyer)
                    })}
                    dataSource={flyer}
                    columns={columns}
                    rowKey="_id"
                    scroll={{ y: '65vh' }}
                    pagination={false}
                    loading={loading}
                />
            </SectionBody>
            <Modal
                title={<>Boost Table<p className={'text-xl font-400'}>Boost this table for free with custom
                    duration</p></>}
                open={isModalOpen}
                width={800}
                okText={'Create Flyer'}
                onCancel={handleCancel}
                onOk={createflyer}
                okButtonProps={{loading: loading, 
                    disabled: 

                    eventName.length == 0
                    || (eventName.length > 0 && eventName.length > 50)
                    || eventLink.length == 0
                    || eventLocation.length == 0
                    || eventDescription.length == 0
                    || (eventDescription.length > 0 && eventDescription.length > 200)
                    || promoImage.length == 0
                    || promoBegin.length == 0
                    || (unlimitedPromo ? false : promoEnd.length == 0)
                }}
            >
                <Form
                form={form}
                layout="vertical"
                >
                    <div className='flex gap-10'>
                        <div className={'flex-1'}>
                            <Form.Item           
                                name="eventName"
                                label="Event Name"
                                rules={[{ required: true, message: 'Please input the event name!'},{max: 50, message: 'Please enter an event name less than 50 characters'}]}
                            >
                                <Input placeholder="Enter event name" onChange={(e) => {setEventName(e.target.value)}}/>
                            </Form.Item>
                            <Form.Item
                                name="venue"
                                label="Venue"
                            >
                                <Input placeholder="Enter venue"  onChange={(e) => {setVenue(e.target.value)}}/>
                            </Form.Item>
                            <Form.Item
                                name="eventLink"
                                label="Event Link"
                                rules={[
                                    { required: true, message: 'Please input the event link!' },
                                    { type: "url", message: "This field must be a valid url." }
                                ]}
                            >
                                <Input placeholder="Enter event link" onChange={(e) => {setEventLink(e.target.value)}}/>
                            </Form.Item>
                            <Form.Item
                                name="eventDate"
                                label="Event Date"   
                            >
                                <DatePicker
                                    onChange={(e) => {e !== null ? setEventDate(e.toString()) : setEventDate('')}}

                                    disabledDate={(date) =>
                                        date.isBefore(dayjs(now()))
                                    }
                                    showTime={{ 
                                        format: 'HH:mm',
                                        showHour: true,
                                        showMinute: true,
                                        showSecond: false
                                    }}
                                    format="YYYY-MM-DD HH:mm"
                                />
                            </Form.Item>
                            <Form.Item

                                name="eventLocation"
                                label="Event Location"
                                rules={[{ required: true, message: 'Please enter a location!'}]}
                                >
                                <AutoComplete
                                    options={options}
                                    placeholder="Type to search city"
                                    onSelect={(value) => {
                                        let selectedCity = cities.find(city => city.value === value)
                                        if (selectedCity) {
                                            setEventLocation(selectedCity.geoNameId)
                                        }
                                    }}
                                    onChange={(value) => {
                                        const filtered = cities.filter(city => 
                                            city.value.toLowerCase().includes(value.toLowerCase())
                                        );
                                        setOptions(filtered);
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div className={'flex-1'}>
                            <Form.Item
                                name="promoImage"
                                label="Promotial Image"
                                rules={[{ required: true, message: 'Please input the event link!' }]}
                            >
                                <Upload 
                                    {...uploadProps}
                                >
                                    {promoImage.length > 0 ? <img src={promoImage} alt="avatar" style={{ width: '100%' }} /> :  <Button icon={<UploadOutlined />}>Upload</Button>}
                                </Upload>
                            </Form.Item>
                        </div>
                    </div>
                    
                    <Form.Item
                        name="eventDescription"
                        label="Event Description"
                        rules={[{required: true, message: 'Please enter a description'},{max: 200, message: 'Please enter a description less than 200 characters'}]}
                    >
                        <Input.TextArea placeholder="Enter event description"  onChange={(e) => {setEventDescription(e.target.value)}}/>
                    </Form.Item>

                    <div className={"flex gap-10"}>
                        <Form.Item
                            name="promoBegin"
                            label="Promotional Begin Date"
                            rules={[{ required: true, message: 'Please select a promotional start date!' }]}
                        >
                            <DatePicker
                                format="YYYY-MM-DD HH:mm"
                                disabledDate={(date) => {
                                    const promoEnd = form.getFieldValue('promoEnd');
                                    if (!promoEnd) return date.isBefore(dayjs(), 'day');
                                    return date.isAfter(promoEnd, 'day') || date.isBefore(dayjs(), 'day');
                                    
                                    }
                                }
                                disabledTime={(date) => {
                                    const promoEnd = form.getFieldValue('promoEnd');
                                    if (!promoEnd || !date) return {};
        
                                    // Only apply time restrictions if it's the same day
                                    if (date.isSame(promoEnd, 'day')) {
                                        return {
                                            disabledHours: () => {
                                                const hours = [];
                                                for (let i = 23; i > promoEnd.hour(); i--) {
                                                    hours.push(i);
                                                }
                                                return hours;
                                            },
                                            disabledMinutes: (selectedHour) => {
                                                if (selectedHour === promoEnd.hour()) {
                                                    const minutes = [];
                                                    for (let i = 59; i > promoEnd.minute(); i--) {
                                                        minutes.push(i);
                                                    }
                                                    return minutes;
                                                }
                                                return [];
                                            }
                                        };
                                    }
                                    return {};
                                }}
                                onChange={(e) => {e !== null ? setPromoBegin(e.toString()) : setPromoBegin('')}}
                                showTime={{ 
                                    format: 'HH:mm',
                                    showHour: true,
                                    showMinute: true,
                                    showSecond: false
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="promoEnd"
                            label="Promotional End Date"
                            rules={[{ required: unlimitedPromo ? false : true, message: 'Please select a promotional end date!' }]}
                        >
                            <DatePicker
                                disabled={unlimitedPromo}
                                disabledDate={(date) => {
                                    const promoBegin = form.getFieldValue('promoBegin');
                                    if (!promoBegin) return date.isBefore(dayjs(), 'day');
                                    return date.isBefore(promoBegin, 'day');
                                    }
                                }
                                disabledTime={(date) => {
                                    const promoBegin = form.getFieldValue('promoBegin');
                                    if (!promoBegin || !date) return {};
        
                                    // Only apply time restrictions if it's the same day
                                    if (date.isSame(promoBegin, 'day')) {
                                        return {
                                            disabledHours: () => {
                                                const hours = [];
                                                for (let i = 0; i < promoBegin.hour(); i++) {
                                                    hours.push(i);
                                                }
                                                return hours;
                                            },
                                            disabledMinutes: (selectedHour) => {
                                                if (selectedHour === promoBegin.hour()) {
                                                    const minutes = [];
                                                    for (let i = 0; i < promoBegin.minute(); i++) {
                                                        minutes.push(i);
                                                    }
                                                    return minutes;
                                                }
                                                return [];
                                            }
                                        };
                                    }
                                    return {};
                                }}
                                onChange={(e) => {e !== null ? setPromoEnd(e.toString()) : setPromoEnd('')}}
                                showTime={{ 
                                    format: 'HH:mm',
                                    showHour: true,
                                    showMinute: true,
                                    showSecond: false
                                }}
                                format="YYYY-MM-DD HH:mm"
                            />
                        </Form.Item>
                    </div>
                    <div className={"flex gap-10"}>
                        <Form.Item
                            name="unlimitedPromo"
                            label="Unlimited Promo?"
                            rules={[{ required: true, message: 'Please select if venue has an unlimited promotional period' }]}
                        >
                            <Switch  
                                checkedChildren="Yes" 
                                unCheckedChildren="No" 
                                onChange={(e) => {
                                    if(e) {
                                        setPromoEnd('');
                                        form.setFieldValue('promoEnd','');
                                    }
                                    setUnlimitedPromo(e)
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="paid"
                            label="Paid for flyer?"
                            rules={[{ required: true, message: 'Please select if venue has paid for flyer' }]}
                        >
                            <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={setIsPaid}/>

                        </Form.Item>
                    </div>
                   
                </Form>
            </Modal>
            
        </>
    );
};

export default Flyers; 